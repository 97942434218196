import React, { useEffect, useRef, useState } from "react";
import ContentList from "../ContentList";
import ContentWrapper from "../ContentWrapper";
import {
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicDetailsWrapper,
  MusicHeaderWrapper,
  MusicHeading,
  MusicHeadingBgImage,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingImageWrapper,
  MusicHeadingSmall,
  MusicHeadingTextWrapper,
  MusicHeadingWrapper,
  MusicInfoWrapper,
  MusicLyricsWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageWrapper,
  MusicPlayerWrapper,
  MusicPlayPauseButton,
  MusicSubheading,
  Option,
  OptionButton,
  OptionMenuButton,
  OptionText,
  ProgressBar,
  ProgressBarWrapper,
  PurchaseButton,
  Select,
  SongDetailsText,
  SongDetailsWrapper,
  SubHeading,
  Text,
} from "./MusicPageElements";
import { useAuth } from "../../contexts/AuthContext";
import { filterLikes, getArtistsNames, getArtistsNames2, getSongTitle, stringFormatter, timestampToDate } from "../../utils";
import { useNavigate, useLocation } from "react-router-dom";
import QRCodeForm from "../QRCodeForm";
import { useAlert } from "../Alert/AlertContext";

import {
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
} from "../../utils/Enum";
import Modal from "../Modal";
import { useModal } from "../Modal/ModalContext";
import MusicInfo from "./MusicInfo";
import { NavContainer } from "../NavLink/NavLinkElements";
import MusicEditPage from "../MusicEditPage";
import ScrollToTop from "../ScrollToTop";
import { MdExplicit } from "react-icons/md";
import SongStats from "../MusicStatsPage/SongStats";
import OptionModal from "../OptionModal";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";
import LyricSynchronizer from "../LyricSynchronizer";
import { Form, FormContent1, FormH1, FormWrapper } from "../CustomForm/CustomFormElements";
import ShareButton from "../ShareButton";

function SongPage({
  id,
  song,
  isSongPlaying,
  handleToggle,
  audioPlayer,
  deleteSong,
  timeTravel,
  hasPurchased,
  currentSong,
  playlist,
  query,
  location,
}) {
  const { currentUser, profiles, currentProfile } = useAuth();
  const {goTo} = ScrollToTop()
  const { modalContent, toggleModal, isOpen, openModal, closeModal } =
    useModal();
  const [currentTab, setTab] = useState('about')
  const navigate  = useNavigate();
  const [body, setBody] = useState({});
  const [isLoaded, setLoaded] = useState(false)
  const [isASLoaded, setASLoaded] = useState(false)
  const { addAlert } = useAlert();
  const [isEditing, setEditing] = useState(false);
  const {themeColors} = useWebsiteTheme()

  const onSuccess = () => {};

  useEffect(() => {
    const idParam = query.get("edit");
    if (idParam) {
      if (currentUser?.uid === song?.uploadedBy) {
        setEditing(true);
      }
    } else {
      setEditing(false);
    }
    return () => {}
  });

  useEffect(() => {
    if(currentProfile !== 'artist' && currentProfile !== 'label'){
    navigate(-1)
    }else{
      
    if(currentUser?.uid !== song?.uploadedBy && ((currentProfile === 'label' && ((profiles?.label?.artists?.length > 0  && !profiles?.label?.artists?.some(a => song?.artists?.includes(artist => artist?.artistId === a?.artistId))) || profiles?.label?.artists?.length === 0))  || (currentProfile === 'artist' && !song?.artists?.some(a => a?.artistId === profiles?.artist?.artistId) ))){
      navigate(-1)
      
    }
  }
    return () => {}
  }, [id])


  useEffect(() => {
    setLoaded(false)
    setASLoaded(false)
    
      return () => {}
  }, [id])
  
  return (
    <>
      <MusicPageWrapper>
        <MusicHeadingContainer>
          <MusicHeadingBgImage
            src={song.albumArt}
            alt={song.songName}
          ></MusicHeadingBgImage>
          <MusicHeadingWrapper>
            <MusicHeadingImageWrapper>
              <MusicHeadingImage src={song.albumArt} alt={song.songName} />
            </MusicHeadingImageWrapper>
            <MusicHeadingTextWrapper>
            <SubHeading><small>{song?.album ? <div style={{color: themeColors.secondaryBg, cursor: "pointer"}} onClick={() => goTo(`/album/${song?.album?.albumId}`)}>from Album {song?.album?.albumName}</div> : `Single`}</small></SubHeading>

              <MusicHeading>{getSongTitle(song)}{song?.explicit && <MdExplicit style={{ marginLeft: "1%" }} />}</MusicHeading>
              <MusicSubheading>
                <MusicHeading
                  style={{ cursor: "pointer" }}
                >
                  {song.artists.map((artist, index) => {
                    if (index < song.artists.length - 1) {
                      return <div onClick={() =>
                        goTo(`#`)
                      }>{artist.artistName + " · "}</div>;
                    } else {
                      return <div onClick={() =>
                        goTo(`#`)
                      }>{artist.artistName}</div>;
                    }
                  })}
                </MusicHeading>
              </MusicSubheading>
              <MusicHeadingSmall>{`${song?.genre?.name} · ${song?.isPreRelease ? `Pre Release ·` : ""}${song?.isPreRelease ? `${new Date(timestampToDate(song?.releaseDate)).toLocaleString('default', { month: 'long' })} ${new Date(timestampToDate(song?.releaseDate)).getDate()}, ${new Date(timestampToDate(song?.releaseDate)).getFullYear()}` : new Date(timestampToDate(song?.releaseDate)).getFullYear()}`}</MusicHeadingSmall>

              <MusicOptionsContainer>
                
                  {currentUser?.uid === song?.uploadedBy && <><MusicPlayPauseButton onClick={() => {
                    const params = new URLSearchParams({ edit: true });
                    navigate(`${location.pathname}?edit=true`,{
                      replace:true},
                    );

                 }}>
                   Edit
                 </MusicPlayPauseButton>

                {song?.lyrics?.trim()?.length > 0 && <MusicPlayPauseButton  onClick={() => {
                    openModal()
                    modalContent(<Form>
                      <FormH1>Lyric Synchronizer</FormH1>
                      <FormContent1>
                      <LyricSynchronizer song={song}/>
                   </FormContent1>
                      </Form>)
                  }}>
                  Synchronize Lyrics 
                 </MusicPlayPauseButton>}
                 
                 {!song?.album && <MusicPlayPauseButton transparent onClick={() => {
                  openModal()
                    modalContent(<OptionModal heading={'Coming Soon'} additionalText={'Distribute your song to all major web2 and web3 platforms and earn your royalties in XRP'}>
                      <HoverOption onClick={() => {
                        closeModal()
                        modalContent()
                      }}>OK</HoverOption>
                    </OptionModal>)

                 }}>
                   Distribute <AiOutlineDeliveredProcedure style={{marginLeft: '2%'}}/>
                 </MusicPlayPauseButton>
                 } <MusicPlayPauseButton transparent onClick={async () => {
                  openModal()
                  modalContent(<ShareButton url={`https://sonarmuse.org/song/${song?.songId}`} media={song.albumArt} title={(song.isPreRelease && !song.isPreview) ? `New music coming soon. I'm ready for the track '${getSongTitle(song)}' - ${getArtistsNames2(song.artists, ARTIST_SEARCH_TYPES.NAME)} on Sonar Muse` : `This is the best thing since sliced bread! Listen to the track '${getSongTitle(song)}' - ${getArtistsNames2(song.artists, ARTIST_SEARCH_TYPES.NAME)} on Sonar Muse`} />);
                }}>
                    Share
                 </MusicPlayPauseButton></>}
              </MusicOptionsContainer>
            </MusicHeadingTextWrapper>
          </MusicHeadingWrapper>
        </MusicHeadingContainer>
        {!isEditing && (
          <>
            <NavContainer>
              <MusicNav>
                <MusicNavLink
                  onClick={() => setTab("about")}
                  active={currentTab === "about"}
                >
                  About {song?.songName}
                </MusicNavLink>
                <MusicNavLink
                  onClick={() => setTab("stats")}
                  active={currentTab === "stats"}
                >
                  Statistics
                </MusicNavLink>
              </MusicNav>
            </NavContainer>
        
            {currentTab === "about" && (
              <MusicInfo type={CONTENT_TYPES.SONG} content={song} />
            )}
            {song?.lyrics?.trim()?.length > 0 && currentTab === "lyrics" && (
             <></>

            )}
            {currentTab === "stats" && <ContentWrapper>
            <SongStats song={song?.songId}/>
            </ContentWrapper>}
          </>
        )}
        {isEditing && <MusicEditPage type={"song"} content={song} />}
      </MusicPageWrapper>
    </>
  );
}

export default SongPage;
