import React, { useState } from 'react'
import OptionModal from '../OptionModal'
import { HoverOption } from '../MenuOptions/MenuOptionsElements'
import { useModal } from '../Modal/ModalContext';
import { UnavailableFeature } from '../MenuOptions';
import { FormInput1, FormLabel, FormText, FormWrapper } from '../CustomForm/CustomFormElements';
import ContentWrapper from '../ContentWrapper';
import { ALERT_TYPES, ARTIST_FORM_INPUT_TYPES, ARTIST_SEARCH_TYPES, NFT_STORAGE_TYPES, SLIDER_TYPES, TRANSACTION_OPTIONS } from '../../utils/Enum';
import { useAlert } from '../Alert/AlertContext';
import ScrollToTop from '../ScrollToTop';
import QRCodeForm from '../QRCodeForm';
import { getObjectLength, validateAddress, xrpAddressRegex } from '../../utils';
import PageIndexer from '../PageIndexer';
import { ButtonWrapper } from '../Profile/ProfileDetails/ProfileDetailsElements';
import { Button, CustomButton } from '../ButtonElement';
import { ArtistContentHeading, ErrorText, SettingsWrapper } from '../ArtistProfile/ArtistContent/ArtistContentElements';
import { emailRegex } from '../../utils/Regex';
import { Header } from '../Header';
import { SmallText } from '../NFTCollectionCreator/NFTCollectionCreatorElements';
import { SongDetailsText } from '../MusicUploadsForm/MusicUploadsFormElements';
import Checkbox from '../Checkbox';
import { TextRoute } from '../QRCodeForm/QrCodeFormElements';
import { useAuth } from '../../contexts/AuthContext';
import ToggleButton from '../ToggleButton';
import { ArtistNavImg, ArtistRegistrationNavLink } from '../RegistrationForm/RegistrationFormElements';
import { ServicesWrapper } from '../Services/ServicesElements';
import { Subtitle } from '../InfoSection/InfoElements';
import { Slider } from '../Slider';
import useArtist from '../../hooks/useArtist';
import { DEFAULT_IMAGE } from '../GravatarAPI';
import { ModalLoadingScreen } from '../LoadingScreen';

const LabelArtistAdder = () => {
    const { openModal, closeModal, modalContent } = useModal();
    const {goTo} = ScrollToTop()
  return (
    <OptionModal heading={'Label Artist Roster Adder'}>
        <HoverOption onClick={() => {
            openModal()
            modalContent(<UnavailableFeature/>)
            //goTo('/requester/artistAdder')
        }}>
            Create New Artist Profile
        </HoverOption>
        <HoverOption onClick={() => {
            openModal()
            modalContent(<UnavailableFeature/>)
             //goTo('/requester/artistClaimer')
        }}>
            Claim Artist Profile
        </HoverOption>
        <HoverOption onClick={() => {
            openModal()
            modalContent(<UnavailableLabelFeature/>)
        }}>
            Request Artist For Label
        </HoverOption>
    </OptionModal>
  )
}

function UnavailableLabelFeature (){
    const { modalContent, openModal, closeModal } = useModal();

  return (
    <>
      <OptionModal heading={"This feature is currently being worked on"} 
      additionalText={'If you would like to make changes to your artist roster, contact the sonar muse team to manually make the changes for you'}>

        <HoverOption
          onClick={() => {
            modalContent();
            closeModal();
          }}
        >
          OK
        </HoverOption>
      </OptionModal>
    </>
  );
}

export function LabelArtistCreator({artist}){
    /*
    Create artist profile with parameters that the label has control of
    if the label adds the artist wallet address then check if artist is registered on the platform.
    if artist is registered on platform, send email or push notification to confirm artist page being created
    Ensure current user does not have an existing artist page linked to theur address
    */
    const {currentUser, profiles} = useAuth()
    const {openModal, closeModal, modalContent} = useModal()
    const {addAlert} = useAlert()
    const {goTo} = ScrollToTop()
    const [artistName, setName] = useState(artist?.name ? artist?.name : '')
    const [artistId, setArtistId] = useState(artist?.artistId ? artist?.artistId : undefined)
    const [fullName, setFullName] = useState(artist?.fullName ? artist?.fullName : '')
    const [address, setAddress] = useState(artist?.uid ? artist?.uid :'')
    const [accInfo, setAccInfo] = useState()
    const [paymentPointer, setPaymentPointer] = useState(artist?.paymentPointer ? artist?.paymentPointer : '')
    const [email, setEmail] = useState(artist?.email ? artist?.email : '')
    const [nftStorageApiKey, setNftStorageApiKey] = useState(artist?.bio ? artist?.bio : '')
    const [bio, setBio] = useState(artist?.bio ? artist?.bio : '')
    const [errors, setErrors] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [storageType, setStorageType] = useState( artist?.ipfsStorage?.type ? artist?.ipfsStorage?.type : 'default')
    const [storageApiKeys, setApiKeys] = useState(artist?.ipfsStorage?.apiKeys ? artist?.ipfsStorage?.apiKeys :{})
    const [addressType, setAddressType] = useState(false)



  const handleInput = (e) => {
      switch (e.target.name) {
          case ARTIST_FORM_INPUT_TYPES.NAME:
              setName(e.target.value)
              
              break;
          case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
              setFullName(e.target.value)

              break;
          case ARTIST_FORM_INPUT_TYPES.EMAIL:
              setEmail(e.target.value)

              break;
           case ARTIST_FORM_INPUT_TYPES.ADDRESS: 
              setAddress(e.target.value)
              break;
              case ARTIST_FORM_INPUT_TYPES.PAYMENT_POINTER:
              setPaymentPointer(e.target.value)
              break;
          case ARTIST_FORM_INPUT_TYPES.NFT_STORAGE_API_KEY:
              setNftStorageApiKey(e.target.value)
              break;
      
          default:
              break;
      }
  }

  const onAddressSuccess = (accInfo) => {
      //console.log(accInfo)
      setAddress(accInfo?.account?.Account)
      closeModal()
      modalContent()
  } 

  const onAddressFail = () => {
      closeModal()
      modalContent()
  }

  const handleApiKeyInput = (e) => {
      switch (e.target.name) {
        case 'key':
          switch (storageType) {
            case NFT_STORAGE_TYPES.FILEBASE:
              let filebase = storageApiKeys?.filebase
              if(e.target.value?.trim()?.length > 0){
              setApiKeys(prev => {
                return {
                  ...prev,
                  filebase: {
                    ...prev?.filebase,
                    key: e.target.value
                  }
                }
              })
            }else{
              delete filebase?.key
              setApiKeys(prev => {
                return {
                  ...prev,
                  filebase
                }
              })
            }
              break;
            case NFT_STORAGE_TYPES.INTERNET_ARCHIVE:
              let internetArchive = storageApiKeys?.internetArchive
              if(e.target.value?.trim()?.length > 0){
              setApiKeys(prev => {
                return {
                  ...prev,
                  internetArchive: {
                    ...prev?.internetArchive,
                    key: e.target.value
                  }
                }
              })}else{
                delete internetArchive.key
                setApiKeys(prev => {
                  return {
                    ...prev,
                    internetArchive
                  }
                })
              }
              break;
            case NFT_STORAGE_TYPES.LIGHTHOUSE:
              let lighthouse = storageApiKeys?.lighthouse
              if(e.target.value?.trim()?.length > 0){
              setApiKeys(prev => {
                return {
                  ...prev,
                  lighthouse: {
                    ...prev?.lighthouse,
                    key: e.target.value
                  }
                }
              })}else{
                delete lighthouse.key
                setApiKeys(prev => {
                  return {
                    ...prev,
                    lighthouse
                  }
                })
              }
              break;
            case NFT_STORAGE_TYPES.PINATA:
              let pinata = storageApiKeys?.pinata
              if(e.target.value?.trim()?.length > 0){
              setApiKeys(prev => {
                return {
                  ...prev,
                  pinata: {
                    ...prev?.pinata,
                    key: e.target.value
                  }
                }
              })}else{
                delete pinata.key
                setApiKeys(prev => {
                  return {
                    ...prev,
                    pinata
                  }
                })
              }
              break;
            case NFT_STORAGE_TYPES.WEB3_STORAGE:
              let web3Storage = storageApiKeys?.web3Storage
              if(e.target.value?.trim()?.length > 0){
              setApiKeys(prev => {
                return {
                  ...prev,
                  web3Storage: {
                    ...prev?.web3Storage,
                    key: e.target.value
                  }
                }
              })}else{
                delete web3Storage.key
                setApiKeys(prev => {
                  return {
                    ...prev,
                    web3Storage
                  }
                })
              }
              break;
          
            default:
              break;
          }
          
          break;
        case 'secret':
          switch (storageType) {
            case NFT_STORAGE_TYPES.FILEBASE:
              let filebase = storageApiKeys?.filebase
              if(e.target.value?.trim()?.length > 0){
              setApiKeys(prev => {
                return {
                  ...prev,
                  filebase: {
                    ...prev?.filebase,
                    secret: e.target.value
                  }
                }
              })}else{
                delete filebase.secret
                setApiKeys(prev => {
                  return {
                    ...prev,
                    filebase
                  }
                })
              }
              break;
            case NFT_STORAGE_TYPES.INTERNET_ARCHIVE:
              let internetArchive = storageApiKeys?.internetArchive
              if(e.target.value?.trim()?.length > 0){
              setApiKeys(prev => {
                return {
                  ...prev,
                  internetArchive: {
                    ...prev?.internetArchive,
                    secret: e.target.value
                  }
                }
              })}else{
                delete internetArchive.secret
                setApiKeys(prev => {
                  return {
                    ...prev,
                    internetArchive
                  }
                })
              }
              break;
            case NFT_STORAGE_TYPES.LIGHTHOUSE:
              let lighthouse = storageApiKeys?.lighthouse
              if(e.target.value?.trim()?.length > 0){
              setApiKeys(prev => {
                return {
                  ...prev,
                  lighthouse: {
                    ...prev?.lighthouse,
                    secret: e.target.value
                  }
                }
              })}else{
                delete lighthouse.secret
                setApiKeys(prev => {
                  return {
                    ...prev,
                    lighthouse
                  }
                })
              }
              break;
            case NFT_STORAGE_TYPES.PINATA:
              let pinata = storageApiKeys?.pinata
              if(e.target.value?.trim()?.length > 0){
              setApiKeys(prev => {
                return {
                  ...prev,
                  pinata: {
                    ...prev?.pinata,
                    secret: e.target.value
                  }
                }
              })}else{
                delete pinata.secret
                setApiKeys(prev => {
                  return {
                    ...prev,
                    pinata
                  }
                })
              }
              break;
            case NFT_STORAGE_TYPES.WEB3_STORAGE:
              let web3Storage = storageApiKeys?.web3Storage
              if(e.target.value?.trim()?.length > 0){
              setApiKeys(prev => {
                return {
                  ...prev,
                  web3Storage: {
                    ...prev?.web3Storage,
                    secret: e.target.value
                  }
                }
              })}else{
                delete web3Storage.secret
                setApiKeys(prev => {
                  return {
                    ...prev,
                    web3Storage
                  }
                })
              }
              break;
          
            default:
              break;
          }
          break;
        case 'jwt':
          setApiKeys(prev => {
            return {
              ...prev,
              pinata: {
                ...prev?.pinata,
                jwt: e.target.value
              }
            }
          })
          break;
        case 'bucketName':
          setApiKeys(prev => {
            return {
              ...prev,
              filebase: {
                ...prev?.filebase,
                bucketName: e.target.value
              }
            }
          })
          break;
      
        default:
          break;
      }
    
    }

  const validateStorageApiKeys = () => {
      let errors = {}
      
          if(storageType === NFT_STORAGE_TYPES.FILEBASE){
            if(getObjectLength(storageApiKeys?.filebase) < 3 ){
              errors = {
                ...errors,
                filebase: 'Missing API Data. Please fill in all the information'
              }
            }
          }
          if(storageType === NFT_STORAGE_TYPES.INTERNET_ARCHIVE){
            if(getObjectLength(storageApiKeys?.internetArchive) < 2 ){
              errors = {
                ...errors,
                internetArchive: 'Missing API Data. Please fill in all the information'
              }
            }
          }
          if(storageType === NFT_STORAGE_TYPES.LIGHTHOUSE){
            if(getObjectLength(storageApiKeys?.lighthouse) < 1 ){
              errors = {
                ...errors,
                lighthouse: 'Missing API Data. Please fill in all the information'
              }
            }
          }
          if(storageType === NFT_STORAGE_TYPES.PINATA){
            if(getObjectLength(storageApiKeys?.pinata) < 3 ){
              errors = {
                ...errors,
                pinata: 'Missing API Data. Please fill in all the information'
              }
            }
          }
  
          if(getObjectLength(storageApiKeys?.filebase) > 0 && getObjectLength(storageApiKeys?.filebase) < 3 ){
            errors = {
              ...errors,
              filebase: 'Missing API Data. Fill in all the information, or clear it'
            }
          }
  
          if(getObjectLength(storageApiKeys?.internetArchive) > 0 && getObjectLength(storageApiKeys?.internetArchive) < 2 ){
            errors = {
              ...errors,
              internetArchive: 'Missing API Data. Fill in all the information, or clear it'
            }
          }
  
          if(getObjectLength(storageApiKeys?.pinata) > 0 && getObjectLength(storageApiKeys?.pinata) < 3 ){
            errors = {
              ...errors,
              pinata: 'Missing API Data. Fill in all the information, or clear it'
            }
          }
        console.log(errors)
        return errors
      }

  const verifyInfo = () => {
      let err = {}
      setErrors()
      if(addressType && !validateAddress(address)){
        err = {
          ...err,
          address: 'Invalid wallet address'
      }
      }
      if(artistName?.trim()?.length === 0){
          err = {
              ...err,
              name: 'Artist name cannot be empty'
          }
      }
      if((email?.trim()?.length === 0) || !emailRegex.test(email)){
          err = {
              ...err,
              email: 'Please enter a valid email address'
          }
      }
      if(paymentPointer?.trim().length > 0 && !paymentPointer.includes('ilp.')){
          err = {
              ...err,
              paymentPointer: 'Payment Pointer is invalid'
          }
      }
      if(addressType && (address?.trim()?.length === 0)){
          err = {
              ...err,
              walletAddress: 'Please add a wallet address'
          }
      }

      let storageErrors = validateStorageApiKeys()
  if(getObjectLength(storageErrors) > 0){
    err = {
      ...err,
      storageApiKeys: storageErrors,
    }
  }
      setErrors(err)
      if(getObjectLength(err) === 0){
          return true
      }
      if(getObjectLength(err) > 0){
          return false
      }
  }

  const onRegistrationFail = () => {
      closeModal()
      modalContent()
  }


  const onRegistrationSuccess = () => {
      goTo('/home')
      localStorage.removeItem("address")
      localStorage.removeItem("regType")
      modalContent(<>
          <OptionModal heading={'Artist Account Registration'}>
              <FormText>Your artist {'registration'} is being processed. You will receive a notification to your Xaman wallet shortly. </FormText>
              <HoverOption onClick={() => {
                  closeModal()
                  modalContent()
              }}>Ok</HoverOption>
          </OptionModal>
      </>)
      
  }



  const submitCreate = () => {
     if(verifyInfo()){
      
      openModal()
      modalContent(<QRCodeForm 
          heading={'Artist Account Registration'}
          type={artist ? TRANSACTION_OPTIONS.LABEL_ARTIST_CLAIM : TRANSACTION_OPTIONS.LABEL_ARTIST_REGISTRATION}
          body={{
              sender: address,
              profile: {
                  name: artistName,
                  email: email,
                  recordLabels: [currentUser?.profiles?.label?.labelId],
                  paymentPointer: paymentPointer,
                  fullName: fullName,
                  address: addressType === 'custom' ? address : undefined,
                  bio: bio.trim().length > 0 ? bio : undefined,
                  ipfsStorage: { type: storageType,
                      apiKeys: storageApiKeys
                    },

              },
              artistId: artistId,
              labelId: currentUser?.profiles?.label?.labelId,
              memo: artist ? 'Label is signing transaction to claim artist page for ' + artistName : 'Label is signing transaction to create artist page for ' + artistName ,
              instruction: artist ? 'Label is signing transaction to claim artist page for ' + artistName : 'Label is signing transaction to create artist page for ' + artistName ,
          }}
          onSuccess={onRegistrationSuccess}
          onFail={onRegistrationFail}
          cancelQr={() => {
              closeModal()
              modalContent()
          }}
          />)
     }else{
      addAlert({
          title: "Artist Registration",
          type: ALERT_TYPES.WARNING,
          message: 'Please fill in form',
          secs: 10,
        });
     }
  }

return (
  <>
  <Header title={'Label Artist Creator'}/>
  <ContentWrapper>
    { currentPage === 1 && <>
    
    <FormWrapper>
          <FormLabel>Artist Name</FormLabel>
          <FormInput1 name={ARTIST_FORM_INPUT_TYPES.NAME} value={artistName} onChange={handleInput} placeholder={'Artist Name'}/>
          <SmallText>Artists Stage Name. This will be used for displaying in your profile and music</SmallText>
          {errors?.name && <SongDetailsText error >{errors?.name}</SongDetailsText>}
      </FormWrapper>
      <FormWrapper>
      <FormLabel>Full Name (Optional)</FormLabel>
          <FormInput1 name={ARTIST_FORM_INPUT_TYPES.FULL_NAME} value={fullName} onChange={handleInput} placeholder={'Full Name'}/>
          <SmallText>Artists full (real) name. This will be used for any music credits</SmallText>
      </FormWrapper>
      <FormWrapper>
      <FormLabel>Email Address</FormLabel>
          <FormInput1 name={ARTIST_FORM_INPUT_TYPES.EMAIL} type={"email"} value={email} onChange={handleInput} placeholder={'Email Address'}/>
          <SmallText>Email Address to cantact artist. Gravatar account information from this address will be used within the artist profile </SmallText>
          {errors?.email && <SongDetailsText error>{errors?.email}</SongDetailsText>}
      </FormWrapper>
             
      </>}

      {currentPage === 2 && 
          <> <FormWrapper>
          <FormLabel>Wallet Address </FormLabel>
          <div style={{ width: '100%', justifyItems: 'flex-start', padding: '0 0 2% 0' }}><ToggleButton onText={"Custom"} offText={'Label Address'} toggle={addressType} toggleClick={() => {
            console.log(addressType)
            setAddressType(!addressType)
          }}/></div>
          {addressType && <FormWrapper row>
            
              <FormInput1 name={ARTIST_FORM_INPUT_TYPES.ADDRESS} onChange={handleInput} value={address} placeholder={'Wallet Address'}/>
            </FormWrapper>}
              <SmallText>A wallet address that will be used to access the artist page . This will be used for receiving payments and signing artist related transactions. By default the label's wallet address will be used</SmallText>

              {errors?.address && <SongDetailsText error>{errors?.address}</SongDetailsText>}
          </FormWrapper>
          <FormWrapper>
          <FormLabel>Payment Pointer (Optional)</FormLabel>
              <FormInput1 name={ARTIST_FORM_INPUT_TYPES.PAYMENT_POINTER} value={paymentPointer} onChange={handleInput} placeholder={'Payment Pointer'}/>
              <SmallText>This will be used for receiving micropayments and tips using the Interledger Protocol</SmallText>
              {errors?.paymentPointer && <SongDetailsText error>{errors?.paymentPointer}</SongDetailsText>}
          </FormWrapper>
          <FormWrapper>
<ArtistContentHeading>IPFS (NFT Minting Settings)</ArtistContentHeading>
<FormText>Select supported storage option for music files and metadata. Sonar Muse will automatically upload audio files for NFT collections to selected option.</FormText>
<FormText>Note: All NFT metadata files will be uploaded to IPFS, regardless of the selected option.</FormText>
<FormText>By Default, Sonar Muse uploads files to it's IPFS storage bucket</FormText>
<FormWrapper>
<SettingsWrapper>
        <div style={{justifyContent: 'flex-start', justifySelf: 'flex-start'}}>
        <Checkbox onClick={() => setStorageType('default')} label={'Sonar Muse Storage Bucket (Default)'} isChecked={storageType === 'default'}/>
        <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.FILEBASE)} label={'Filebase'} isChecked={storageType === NFT_STORAGE_TYPES.FILEBASE}/>
        <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.INTERNET_ARCHIVE)} label={'Internet Archive'} isChecked={storageType === NFT_STORAGE_TYPES.INTERNET_ARCHIVE}/>
        <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.LIGHTHOUSE)} label={'Lighthouse'} isChecked={storageType === NFT_STORAGE_TYPES.LIGHTHOUSE}/>
        <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.PINATA)} label={'Pinata'} isChecked={storageType === NFT_STORAGE_TYPES.PINATA}/>
        {/* <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.WEB3_STORAGE)} label={'Web3 Storage (Storacha)'} isChecked={storageType === NFT_STORAGE_TYPES.WEB3_STORAGE}/> */}

        </div>
</SettingsWrapper>
   {(storageType === NFT_STORAGE_TYPES.FILEBASE || errors?.storageApiKeys?.filebase) && <FormWrapper border>
   <FormLabel>Filebase</FormLabel>
   <FormText>To learn more about and create a Filebase account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://filebase.com/'}>click here</TextRoute></FormText>
  <FormWrapper>
    <FormLabel>Bucket Name</FormLabel>
    <FormInput1 placeholder="Bucket Name" name={'bucketName'} onChange={handleApiKeyInput} value={storageApiKeys?.filebase?.bucketName}/>
  </FormWrapper>
  <FormWrapper>
    <FormLabel>API KEY</FormLabel>
    <FormInput1 placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.filebase?.key}/>
  </FormWrapper>
  <FormWrapper>
    <FormLabel>API SECRET</FormLabel>
    <FormInput1 placeholder={"API SECRET"} name={'secret'} onChange={handleApiKeyInput} value={storageApiKeys?.filebase?.secret}/>
  </FormWrapper>
  {errors?.storageApiKeys?.filebase && <ErrorText>{errors?.storageApiKeys?.filebase}</ErrorText>}
</FormWrapper>}
   {(storageType === NFT_STORAGE_TYPES.INTERNET_ARCHIVE || errors?.storageApiKeys?.internetArchive) && <FormWrapper border>
   <FormLabel>Internet Archive</FormLabel>
   <FormText>To learn more about and create an Internet Archive account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://archive.org/'}>click here</TextRoute></FormText>
  <FormWrapper>
    <FormLabel>API KEY</FormLabel>
    <FormInput1 placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.internetArchive?.key}/>
  </FormWrapper>
  <FormWrapper>
    <FormLabel>API SECRET</FormLabel>
    <FormInput1  placeholder={"API SECRET"} name={'secret'} onChange={handleApiKeyInput} value={storageApiKeys?.internetArchive?.secret}/>
  </FormWrapper>
  {errors?.storageApiKeys?.internetArchive && <ErrorText>{errors?.storageApiKeys?.internetArchive}</ErrorText>}
</FormWrapper>}
   {(storageType === NFT_STORAGE_TYPES.LIGHTHOUSE || errors?.storageApiKeys?.lighthouse) && <FormWrapper border>
   <FormLabel>Lighthouse</FormLabel>
   <FormText>To learn more about and create a Lighthouse account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://files.lighthouse.storage/'}>click here</TextRoute></FormText>
  <FormWrapper>
    <FormLabel>API KEY</FormLabel>
    <FormInput1 placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.lighthouse?.key}/>
  </FormWrapper>
  {errors?.storageApiKeys?.lighthouse && <ErrorText>{errors?.storageApiKeys?.lighthouse}</ErrorText>}
</FormWrapper>}
   {(storageType === NFT_STORAGE_TYPES.PINATA || errors?.storageApiKeys?.pinata) && <FormWrapper border>
   <FormLabel>Pinata</FormLabel>
   <FormText>To learn more about and create a Pinata account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://www.pinata.cloud/'}>click here</TextRoute></FormText>
  <FormWrapper>
    <FormLabel>API KEY</FormLabel>
    <FormInput1  placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.pinata?.key}/>
  </FormWrapper>
  <FormWrapper>
    <FormLabel>API SECRET</FormLabel>
    <FormInput1  placeholder={"API SECRET"} name={'secret'} onChange={handleApiKeyInput} value={storageApiKeys?.pinata?.secret}/>
  </FormWrapper>
  <FormWrapper>
    <FormLabel>JWT</FormLabel>
    <FormInput1  placeholder={'JWT'} name={'jwt'} onChange={handleApiKeyInput} value={storageApiKeys?.pinata?.jwt}/>
  </FormWrapper>
  {errors?.storageApiKeys?.pinata && <ErrorText>{errors?.storageApiKeys?.pinata}</ErrorText>}
</FormWrapper>}
   {/* (storageType === NFT_STORAGE_TYPES.WEB3_STORAGE || errors?.storageApiKeys?.web3storage) && <FormWrapper>
   <FormLabel>Internet Archive</FormLabel>
  <FormWrapper>
    <FormLabel>API KEY</FormLabel>
    <FormInput1/>
  </FormWrapper>
  <FormWrapper>
    <FormLabel>API SECRET</FormLabel>
    <FormInput1/>
  </FormWrapper>
  {errors?.storageApiKeys?.web3Storage && <ErrorText>{errors?.storageApiKeys?.web3Storage}</ErrorText>}
</FormWrapper> */}

</FormWrapper> 
        
</FormWrapper>
          </>
      }
      {currentPage === 3 && 
          <><FormWrapper>KYC Coming Soon</FormWrapper></>
      }
      <PageIndexer onClick={setCurrentPage} pages={2} currentPage={currentPage}/>
      <ButtonWrapper>
          <Button onClick={() => {
            if(addressType === 'custom' && address === currentUser?.uid && !profiles?.artist){
              openModal()
              modalContent(<OptionModal heading={'Artist profile will be linked to current wallet address. To continue, visit the artist registration form'}>
                <HoverOption onClick={() => {
                  modalContent()
                  goTo('/artist/register?reg=claim')
                }}>Continue</HoverOption>
                <HoverOption onClick={() => {
                  modalContent()
                }}>Cancel</HoverOption>
              </OptionModal>)
              return
            }
            if(addressType === 'custom' && address === currentUser?.uid && profiles?.artist){
              openModal()
            modalContent(<OptionModal heading={'Artist profile is already linked to your wallet address. If you choose to continue, it may replace the linked profile. Continue?'}>
              <HoverOption onClick={() => {
                modalContent()
                submitCreate()
              }}>Continue</HoverOption>
              <HoverOption onClick={() => {
                modalContent()
              }}>Cancel</HoverOption>
            </OptionModal>)
            return
            }
            submitCreate()
            }}>Create Account</Button>
          <CustomButton onClick={() => {goTo(-1)}}>Cancel</CustomButton>
      </ButtonWrapper>
  </ContentWrapper>
  </>
)
}


export function LabelArtistClaimer(){
    /*
        Search for unclaimed artist profile to add to roster
        if artist profile has a wallet address or email address
        We will send the artist a message alerting that a label will be claiming their profile
    */
        const { openModal, closeModal, modalContent } = useModal();
        const { searchArtists, bulkArtistsSearch, getArtistProfile} = useArtist({});
        const { goTo } = ScrollToTop();
        const [artistName, setName] = useState()
        const [artists, setArtists] = useState([])
        const [artist, setArtist] = useState()
        const [artistInfo, setArtistInfo] = useState()
        const [isLoaded, setLoaded] = useState(false)
      
        const handleInput = (e) => {
          setArtists([])
          switch (e.target.name) {
              case ARTIST_FORM_INPUT_TYPES.NAME:
                setLoaded(false)
                  setName(e.target.value)
                  setArtist()
                    searchArtists(ARTIST_SEARCH_TYPES.NAME, e.target.value).then(a => {
                      if(a?.length > 0){
                        setArtists(a?.filter(artist => !artist?.isVerified))
                        
                      }
                      setLoaded(true)
                    }).catch(err => {
                      setLoaded(true)
                    })
                  break;
                  
                  default:
      
                  break;
                }
              }

              const getArtistInfo = async (a) => {
                openModal()
                modalContent(<ModalLoadingScreen text={'Loading Artist Info'}/>)
                return getArtistProfile(a.artistId).then(async(profile) => {
                  if(profile){
                  setArtist()
                  setArtistInfo({...profile?.profile, artistId: profile?.artistId})
                  modalContent()
                  return
                }
                modalContent(<OptionModal heading={'Something went wrong. Please try again'}>
                    <HoverOption onClick={() => {
                      return getArtistInfo(a)
                    }}>Try Again</HoverOption>
                    <HoverOption onClick={() => {
                      modalContent()
                    goTo(-1)
                  }}>OK</HoverOption>
                </OptionModal>) 
              }).catch(err => {
                modalContent(<OptionModal heading={'Something went wrong. Please try again'}>
                  <HoverOption onClick={() => {
                    return getArtistInfo(a)
                  }}>Try Again</HoverOption>
                  <HoverOption onClick={() => {
                    modalContent()
                    goTo(-1)
                  }}>OK</HoverOption>
              </OptionModal>) 
              })
              }
      
      
              const displayArtists = () => {
                return (
                  <>
                   <ServicesWrapper style={{padding: '50px 10px', margin: '0 2%', width:'100%', maxWidth:'100%'}}>
                    {artists?.map(a => {
                    return (<>
                     
                    <ArtistRegistrationNavLink
                    id={a?.artistId}
                      onClick={async () => {
                        return getArtistInfo(a)
                        
                      }}
                    >
                    
                      <ArtistNavImg
                          isRounded
                          size={"8rem"}                     
                          albumArt={a?.artistSettings?.profilePictureType === "custom" ? a?.profilePicture : a?.photoUrl ? `${a?.photoUrl}?s=600` :  DEFAULT_IMAGE(600)}
                        />  
                        {a?.artistName}
                    </ArtistRegistrationNavLink>
      
                    </>)
                  })}</ServicesWrapper>
                  </>
                )
              }
            
      
        return (
          <>
          {!artistInfo && <Header title={'Artist Account Claimer'}/>}
          
          {!artistInfo && <ContentWrapper>
            <Subtitle style={{ color: "white" }}>
                Search for artist page
              </Subtitle> 
            <FormWrapper>
                  <FormInput1 name={ARTIST_FORM_INPUT_TYPES.NAME} value={artistName} onChange={handleInput} placeholder={'Search Artist'}/>
          {isLoaded && (artistName?.trim()?.length > 0) && (artists?.length > 0) && 
            displayArtists()
          }
          {isLoaded && (artists?.length === 0) && !artist && 
            <><FormText>Artist "{artistName}" not found</FormText>
            <CustomButton onClick={() => { goTo('/requester/artistAdder')}}>Create New Artist</CustomButton></>
          }
          {!isLoaded && (artistName?.trim()?.length > 0) && 
            <>
            <Slider type={SLIDER_TYPES.ARTIST_SKELETON} id={'artistSearch'}  heightScroll />
            <Slider type={SLIDER_TYPES.ARTIST_SKELETON} id={'artistSearch'}  heightScroll />
            </>
          }
          {/* {isLoaded && artist && <ArtistInfo artist={artist} setArtist={setArtist} setArtistInfo={setArtistInfo}/>} */}
      
          </FormWrapper>
      
          </ContentWrapper>}
      
          {isLoaded && artistInfo &&  <LabelArtistCreator artist={artistInfo} />}
          
          </>
        )
      }

function LabelArtistRequester(){
/*
    Search for artist from list of verified artist
    choose the control parameters that the label requests has options to have with the artist
    // send request out to artist
    // artist can edit request if they want
    // artist can either accept or decline request
    // Label can cancel request any time.
    \\ on decline - send decline notification to label
    \\ on accept - send accept notification to label and artist and add record label to artist profile
    \\ on edit: artist or label can either accept or decline label request


*/
}

export default LabelArtistAdder