import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { LabelArtistClaimer, LabelArtistCreator } from '../../components/LabelArtistAdder';

const LabelRequesterPage = () => {
  const {type} = useParams()
  const navigate  = useNavigate()

  return (
    <>
    {type === 'artistAdder' && <LabelArtistCreator/>}
    {type === 'artistClaimer' && <LabelArtistClaimer/>}
    </>
  )
}

export default LabelRequesterPage