import React, { useEffect, useState } from 'react'
import useArtist from '../../../hooks/useArtist'
import { getArtistsNames2, getSongTitle } from '../../../utils'
import { SLIDER_TYPES } from '../../../utils/Enum'
import { CustomButton } from '../../ButtonElement'
import { ButtonWrapper } from '../../ContentList/ContentListElements'
import { FormText } from '../../CustomForm/CustomFormElements'
import { DEFAULT_IMAGE } from '../../GravatarAPI'
import { HoverOption } from '../../MenuOptions/MenuOptionsElements'
import { useModal } from '../../Modal/ModalContext'
import OptionModal from '../../OptionModal'
import { ServicesWrapper } from '../../Services/ServicesElements'
import { Slider } from '../../Slider'
import { ArtistNavImg, ArtistRegistrationNavLink } from '../RegistrationFormElements'
import { Nav, NavContainer, NavLink } from '../../NavLink/NavLinkElements'

const ArtistInfo = ({artist, setArtist, setArtistInfo}) => {
    const { getArtistDiscography, getArtistProfile} = useArtist({})
    const {openModal, modalContent, closeModal} = useModal()
    const [currentTab, setCurrentTab] = useState("all")
    const [discography,setDiscography] = useState()
    const [removedDiscography, setRemovedDiscography] = useState([])
    const [isLoaded, setLoaded] = useState(false)
    const [artistProfile, setArtistProfile] = useState()


    useEffect(() => {
      getDiscography()
      return () => {}
    }, [artist])
    

    const getDiscography = async() => {
        return await getArtistProfile(artist).then(async(profile) => {
            //console.log(profile)
            setArtistProfile(profile)
        return await getArtistDiscography(artist,"all").then(disco => {
            let albums = disco?.albums.length > 0 ? disco?.albums?.albums : []
                let songs = disco?.songs.length > 0 ? disco?.songs?.songs : []
                let features = disco?.features?.length > 0 ? disco?.features?.songs : []
                let composed = disco?.composed?.length > 0 ? disco?.composed?.songs : []
                let produced = disco?.produced?.length > 0 ? disco?.produced?.songs : []
                let written = disco?.written?.length > 0 ? disco?.written?.songs : []
               let music = [...new Set([...albums,...songs, ...features, ...written, ...composed, ...produced])]
               setDiscography(music)
            setLoaded(true)
    }).catch(err => {
        console.error(err)
        setLoaded(true)
    })}).catch(err => {
        console.error(err)
        setLoaded(true)
    })
}

  return (
    <>
    {!isLoaded && <>
      <FormText>Loading Artist Information. Please Wait</FormText>
    <Slider type={SLIDER_TYPES.MUSIC_SKELETON} id={'artistSearch'}  heightScroll />
    <Slider type={SLIDER_TYPES.MUSIC_SKELETON} id={'artistSearch'}  heightScroll /></>}
      {isLoaded && <> 
      
        <ArtistNavImg
            size={"8rem"}    
            isRounded                 
            albumArt={artistProfile?.profile?.artistSettings?.profilePictureType
                ? artistProfile?.profile?.artistSettings?.profilePictureType ===
                  "gravatar"
                  ? artistProfile?.gravatarProfile?.avatar_url+ "?s=600"
                  : artistProfile?.profile?.artistSettings?.profilePictureType === "custom"
                  ? artistProfile?.profile?.profilePicture?.url
                  : artistProfile?.gravatarProfile?.avatar_url+ "?s=600"
                : DEFAULT_IMAGE(600)}
          /> 
          <FormText>{artistProfile?.profile?.name}</FormText>
      {discography?.length > 0 && 
       <><NavContainer style={{marginBottom: '2%', marginTop: '2%'}}><Nav>
          <NavLink active={currentTab === 'all'} onClick={() => setCurrentTab('all')}>All</NavLink>
          <NavLink active={currentTab === 'albums'} onClick={() => setCurrentTab('albums')}>Albums</NavLink>
          <NavLink active={currentTab === 'singles'} onClick={() => setCurrentTab('singles')}>Singles</NavLink>
        </Nav></NavContainer>
      <ServicesWrapper style={{margin: '0 2%', width:'100%', maxWidth:'100%'}}>
       
      {discography?.filter(m => currentTab === 'albums' ? m?.albumId : currentTab === 'singles' ? m?.songId : m).map((m, i) => {
      return (<>
       
      <ArtistRegistrationNavLink
        onClick={() => {
            openModal()
          modalContent(<>
            <OptionModal heading={m?.songName ? m?.songName : m?.albumName}>

                <HoverOption onClick={() => {
                    setRemovedDiscography(prev => {return [...prev, m]})
                    setDiscography(discography?.filter((d, index) => index !== i))
                    modalContent()
                    closeModal()
                }}>This is not me</HoverOption>
            </OptionModal>
          </>)
        }}
      >
      
        <ArtistNavImg
            size={"6rem"}                     
            albumArt={m?.albumArt}
          />  
          <text style={{display: 'flex'}}>{m?.songId ? getSongTitle(m) : m?.albumId ? m?.albumName : ''}</text>
          {m?.albumId && <text style={{display: 'flex', textDecoration: 'italics'}}>(album)</text>}
          <text style={{display: 'flex'}}>{getArtistsNames2(m?.artists)}</text>
      </ArtistRegistrationNavLink>

      </>)
    })}</ServicesWrapper></>}
    {discography?.length === 0 && <><FormText>No Discography Found</FormText></>}
    <ButtonWrapper>
        <CustomButton onClick={() => {
            setDiscography()
            setArtist()
            }}>Cancel</CustomButton>
        <CustomButton onClick ={() => setArtistInfo({profile: artistProfile, discography: removedDiscography})}>Confirm Artist</CustomButton>
    </ButtonWrapper></>
    
    }</>

  )
}

export default ArtistInfo