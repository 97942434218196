import React, { useState } from "react";
import {
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicHeading,
  MusicHeadingBgImage,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingImageWrapper,
  MusicHeadingSmall,
  MusicHeadingTextWrapper,
  MusicHeadingWrapper,
  MusicInfoWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageWrapper,
  MusicPlayPauseButton,
  MusicSubheading,
  Option,
  OptionButton,
  OptionMenuButton,
  Select,
  SubHeading,
} from "./MusicPageElements";
import ContentWrapper from "../ContentWrapper";
import { Slider } from "../Slider";
import {
  ALERT_TYPES,
  CONTENT_TYPES,
  MUSIC_PLAYING_FROM,
  PODCASTER_SEARCH_TYPES,
  SLIDER_TYPES,
} from "../../utils/Enum";
import MusicInfo from "./MusicInfo";
import CommentsSection from "../CommentsSection";
import ContentList from "../ContentList";
import { useAuth } from "../../contexts/AuthContext";
import ScrollToTop from "../ScrollToTop";
import { useModal } from "../Modal/ModalContext";
import { useNavigate } from "react-router-dom";
import { FaPause, FaPlay } from "react-icons/fa";
import ImageUtils from "../../utils/ImageUtils";
import MusicEditPage from "../MusicEditPage";
import { NavContainer } from "../NavLink/NavLinkElements";
import { useEffect } from "react";
import { useAlert } from "../Alert/AlertContext";
import { CustomButton } from "../ButtonElement";
import { convertDurationToString, convertTimeToSeconds, getPodcastersNames2, getUniqueListBy, timestampToDate } from "../../utils";
import { FormWrapper as FW } from "../CustomForm/CustomFormElements";
import { HeaderText } from "../AudioPlayer/AudioPlayerPlaylist/AudioPlayerPlaylistElements";
import { FormWrapper } from "../MusicUploadsForm/MusicUploadsFormElements";
import MenuOptions from "../MenuOptions";
import EpisodeStats from "../MusicStatsPage/EpisodeStats";
import ShareButton from "../ShareButton";

const EpisodePage = ({
  id,
  episode,
  isSongPlaying,
  handleToggle,
  audioPlayer,
  hasPurchased,
  currentSong,
  playlist,
  deleteEpisode,
  query,
  location,
}) => {
  const { currentUser, currentProfile, profiles } = useAuth();
  const { goTo } = ScrollToTop();
  const { modalContent, toggleModal, isOpen, openModal, closeModal } =
    useModal();
  const {addAlert} = useAlert()
  const [ currentTab,setTab] = useState('about');
  const { musicOptionsModal } = MenuOptions();
  const { defaultAlbumArt } = ImageUtils();
  const navigate  = useNavigate();
  const [isEditing, setEditing] = useState(false);


  useEffect(() => {
    const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
    if (params?.has("edit")) {
      if (currentUser?.uid === episode?.uploadedBy) {
        setEditing(true);
      }
    } else {
      setEditing(false);
    }
    return () => {}
  });

  useEffect(() => {
    if(currentProfile !== 'podcaster'){
    navigate(-1)
    }else{
      
    if((currentUser?.uid !== episode?.uploadedBy && !episode?.podcasters?.some(a => a?.podcasterId === profiles?.podcaster?.podcasterId) )){
      navigate(-1)
    }
  }
    return () => {}
  }, [id])

  const handleSelect = async (e) => {
    switch (e.target.value) {
      case "delete":
        break;
      case "edit":
        const params = new URLSearchParams({ edit: true });
        navigate(`${location.pathname}?edit=true`,{
          replace:true},
        );

        break;
      default:
        break;
    }
  };


  const togglePlay = (pl) => {
    
  };

  const isPlayingFrom = () => {
    if (
      localStorage?.getItem("isPlayingFrom") && 
      JSON.parse(localStorage?.getItem("isPlayingFrom"))?.playingFrom ===
        MUSIC_PLAYING_FROM.EPISODE &&
      JSON.parse(localStorage.getItem("isPlayingFrom"))?.id === episode?.episodeId
    ) {
      return true;
    }
    return false;

  };

  return (
    <MusicPageWrapper>
      <MusicHeadingContainer>
        <MusicHeadingBgImage
          color={episode?.color}
          src={episode?.thumbnail ? episode?.thumbnail : defaultAlbumArt}
          alt={episode?.episodeName}
        ></MusicHeadingBgImage>
        <MusicHeadingWrapper>
          <MusicHeadingImageWrapper>
            <MusicHeadingImage
              color={episode?.color}
              src={episode?.thumbnail ? episode?.thumbnail : defaultAlbumArt}
              alt={episode?.episodeName}
            />
          </MusicHeadingImageWrapper>
          <MusicHeadingTextWrapper>
            <SubHeading><small>Podcast Episode</small></SubHeading>
            <MusicHeading>{episode?.episodeName}</MusicHeading>
            <MusicSubheading>
              <MusicHeading
                style={{ cursor: "pointer" }}
              
              >
                {episode?.podcasters?.map((podcaster, index) => {
                  if (index < episode?.podcasters?.length - 1) {
                    return <div onClick={() =>
                      goTo(`#`)
                    }>{podcaster?.podcasterName + " · "}</div>;
                  } else {
                    return <div onClick={() =>
                      goTo(`#`)
                    }>{podcaster?.podcasterName}</div>
                  }
                })}
              </MusicHeading>
            </MusicSubheading>
            <MusicHeadingSmall>{`${convertDurationToString(episode?.duration)} · ${new Date(timestampToDate(episode?.releaseDate)).getFullYear()}`}</MusicHeadingSmall>
            <MusicOptionsContainer>
              {!isEditing && (
                <>
                  {currentUser?.uid === episode?.uploadedBy && <MusicPlayPauseButton onClick={() => {
                     const params = new URLSearchParams({ edit: true });
                     navigate(`${location.pathname}?edit=true`,{
                      replace:true},
                    );

                  }}>
                    Edit
                  </MusicPlayPauseButton>}

                  <MusicPlayPauseButton transparent onClick={() => {
                    modalContent(<ShareButton url={`https://sonarmuse.org/episode/${episode?.episodeId}`} media={episode?.thumbnail} title={`Long form content for the win! Listen to '${episode?.episodeName}' - ${getPodcastersNames2(episode?.podcasters, PODCASTER_SEARCH_TYPES.NAME)} on Sonar Muse`} />);
                    
                  }}>
                    Share
                  </MusicPlayPauseButton>
                </>
              )}
            </MusicOptionsContainer>
          </MusicHeadingTextWrapper>
        </MusicHeadingWrapper>
      </MusicHeadingContainer>
      {!isEditing && (
        <>
          <NavContainer>
            <MusicNav>
              <MusicNavLink
                onClick={() => setTab("about")}
                active={currentTab === "about"}
              >
                About {episode?.episodeName}
              </MusicNavLink>
              <MusicNavLink
                onClick={() => setTab("stats")}
                active={currentTab === "stats"}
              >
                Statistics
              </MusicNavLink>
            </MusicNav>
          </NavContainer>
          <MusicDetailsContainer>
            {currentTab === "about" && (
              <MusicInfo type={CONTENT_TYPES.EPISODE} content={episode} />
            )}
            {currentTab === "stats" && (
              <ContentWrapper>
                <EpisodeStats episode={episode?.episodeId}/>
              </ContentWrapper>
            )}
          </MusicDetailsContainer>
        </>
      )}
      {isEditing && <MusicEditPage type={CONTENT_TYPES.EPISODE} content={episode} />}
    </MusicPageWrapper>
  );
};

export default EpisodePage;
