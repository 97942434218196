import React, { useContext, useEffect, useState } from "react";
import { BsCollectionPlayFill, BsDisc, BsDiscFill, BsListCheck, BsUiChecksGrid } from "react-icons/bs";
import { animateScroll as scroll } from "react-scroll";
import { FaAddressCard, FaBars, FaBell, FaComment, FaCommentsDollar, FaFileContract, FaFileDownload, FaFileUpload, FaHandshake, FaHome, FaInfo, FaRecordVinyl, FaSearch, FaSignInAlt, FaSignOutAlt, FaStore, FaTh, FaTimes } from "react-icons/fa";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";

import {
  Container,
  Main,
  MainWrapper,
  NavImg,
  SidebarBtnLink,
  SidebarContainer,
  SidebarLink,
  SideBarLinkIcon,
  SideBarLinkText,
  SidebarLogo,
  SidebarMenu,
  SideBarProfileIcon,
  SidebarTopSection,
  SidebarWrapper,
} from "./SidebarNavElements";
import whitepaper from '../../files/Sonar_Muse_Whitepaper.pdf'
import ImageUtils from "../../utils/ImageUtils";
import { Fade } from "react-reveal";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { MdDashboard, MdFlashlightOff, MdFlashlightOn, MdOutlineAlternateEmail, MdOutlineCampaign, MdOutlineMiscellaneousServices, MdOutlineTravelExplore } from "react-icons/md";
import { GiWhiteBook } from "react-icons/gi";
import { RiPlayListFill } from "react-icons/ri";
import { downloadFile, getObjectLength } from "../../utils";
import BadgeIcon from "../BadgeIcon";
import { useUser } from "../../hooks/useUser";
import ToolTip from "../ToolTip";
import ToggleButton from "../ToggleButton";
import { TbBulbFilled, TbBulbOff } from "react-icons/tb";
import MenuOptions, { MiscellaneousOptions, MusicUploadModal, NftokenCollectionModal, UnavailableFeature } from "../MenuOptions";
import { AiOutlineLineChart } from "react-icons/ai";
import { useModal } from "../Modal/ModalContext";
import { ArtistMenu, ProfilePicker } from "../Dashboard";
import { DEFAULT_IMAGE } from "../GravatarAPI";
import { getArtistNotifications } from "../../utils/dbUtils/artistUtils";
import { getPodcasterNotifications } from "../../utils/dbUtils/podcasterUtils";
import { getRecordLabelNotifications } from "../../utils/dbUtils/recordLabelUtils";
import Notifications from "../Notifications";
import useArtist from "../../hooks/useArtist";
import usePodcaster from "../../hooks/usePodcaster";
import useRecordLabel from "../../hooks/useRecordLabel";
import { NavigationButtons } from "../NavigationButtons";
import LabelArtistAdder from "../LabelArtistAdder";

export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}

export const SidebarContext = React.createContext();

export function useSidebar() {
  return useContext(SidebarContext);
}

const SidebarNav = ({ isOpenVal, children }) => {
  const {toggleTheme, theme } = useWebsiteTheme()
  const {openModal, modalContent, closeModal, toggleModal} = useModal()
  const [scrollNav, setScrollNav] = useState(false);
  const { invertedLogo, invertedLogo2, logo, logo2 } = ImageUtils(); 
  const { currentUser, logout, currentProfile, setCurrentProfile,profiles } = useAuth();
  const [currentTab, setTab] = useState('home')
  const [isOpen, setOpen] = useState(isOpenVal ? isOpenVal : false);
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search)
  const [notifications, setNotifications] = useState()
  const [notificationsLoaded, setNotificationsLoaded] = useState(false)
  const {markArtistNotificationAsRead} = useArtist({})
  const {markPodcasterNotificationAsRead} = usePodcaster({})
  const {markRecordLabelNotificationAsRead} = useRecordLabel({})
  const navigate  = useNavigate()
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    return () => {}
  }, []);

  const getProfileNotifications = async () => {
    setNotificationsLoaded(false)
    if(currentProfile && Object.keys(profiles).length > 0){
      switch (currentProfile) {
        case 'artist':
          getArtistNotifications(profiles.artist?.artistId, setNotifications)
          break;
        case 'podcaster':
          getPodcasterNotifications(profiles?.podcaster?.podcasterId, setNotifications)
          break;
        case 'label':
          getRecordLabelNotifications(profiles?.label?.labelId, setNotifications)
          break;
      
        default:
          setNotifications([])
          break;
      }
    }
    setNotificationsLoaded(true)
  }


  useEffect(() => {
    getProfileNotifications()
    return () => {}
  }, [currentProfile])



  const toggleHome = () => {
    !currentUser?.uid ? navigate( '/') : navigate('/home')
    setOpen(false)
    setTab('home')
    scroll.scrollToTop();
  };
  
  const menuItems = () => {
    let items = [
      {
        path: "/setEmailHash",
        name: "Account Set Email Hash",
        icon: <MdOutlineAlternateEmail />,
      },
      {
        path: "/explore",
        name: "Explore",
        icon: <MdOutlineTravelExplore />,
      },
      {
        path: "/promote",
        name: "Promote",
        icon: <MdOutlineCampaign />,
      },
      {
        path: "/release",
        name: "Release",
        icon: <FaFileUpload />,
      },
      {
        path: "/stats",
        name: "Statistics",
        icon: <AiOutlineLineChart />,
      },
      {
        path: "/signup",
        name: "Sign Up",
        icon: <FaAddressCard/>
      }
    ];

    if (currentUser?.uid) {
      items = [{
        path: getObjectLength(currentUser?.profiles) > 0 ? `/home/${currentProfile}/${currentUser?.profiles[currentProfile][`${currentProfile}Id`]}` : '/home',
        name: "Dashboard",
        icon: <FaHome />,
      },
      {
        onClick:() =>{
          openModal();
          return modalContent(<ArtistMenu type={currentProfile} currentProfile={currentUser?.profiles[currentProfile]}/>);
        },
        name: getObjectLength(currentUser?.profiles) > 0 ? currentUser?.profiles[currentProfile][`${currentProfile
        }Name`] : 'Account',
        icon : <SideBarProfileIcon isOpen={isOpen} src={getObjectLength(currentUser?.profiles) > 0 ? currentUser?.profiles[currentProfile][`${currentProfile
        }Settings`]?.profilePictureType === 'custom' ? currentUser?.profiles[currentProfile]?.profilePicture : currentUser?.profiles[currentProfile]?.photoUrl ? currentUser?.profiles[currentProfile]?.photoUrl : currentUser?.profiles[currentProfile]?.avatar_url : DEFAULT_IMAGE(600)
      } alt={`Profile`}/>,
      },
      {onClick: () => {
       if(currentProfile){
        openModal()
        modalContent(<Notifications notifications={notifications} markNotificationAsRead={currentProfile === 'label' ? markRecordLabelNotificationAsRead : currentProfile === 'podcaster' ? markPodcasterNotificationAsRead : markArtistNotificationAsRead}/>)
       }
        
      },name: "Notifications",
        icon: <FaBell/>},
        currentProfile !== 'podcaster' && currentProfile !== 'artist' && {
          path: '#',
          onClick: () => {
            openModal()
            modalContent(<LabelArtistAdder/>)
          },
          icon: <FaFileContract />,
          name: 'Record Label Requester'
        },
      {
        path: "/marketing",
        name: "Marketing Campaign",
        icon: <MdOutlineCampaign />,
      },  
      {
        path: "/comments",
        name: "Comment Booth",
        /* onClick: () => {
          openModal()
          modalContent(<UnavailableFeature/>)
        }, */
        icon: <FaCommentsDollar />,
      },  
      {
        onClick:  () => {
          openModal();
          modalContent(
            <>
            <MusicUploadModal />
            </>
          );
        } ,
        name: "Upload",
        icon: <FaFileUpload />,
      },
      currentProfile !== 'podcaster' && {
        onClick: () => {
          openModal()
          modalContent(<NftokenCollectionModal/>)
        },
        name: "NFT Creator",
        icon: <BsCollectionPlayFill />,
      },
      
      {
        onClick: () => {
          openModal();
          modalContent(
            <>
            <MiscellaneousOptions />
            </>
          );
        },
        name: "More",
        icon: <MdOutlineMiscellaneousServices />,
      },
      {
        onClick: logout,
        name: 'Sign Out',
        icon: <FaSignOutAlt/>
      }
    ]
    }

    if(currentUser?.uid && window.location.href.includes('/edit')){
      items = [{}]
    }
    
    return items
  };

  const value = {
    isOpen,
    setOpen,
  };

  return (
    <>
      <SidebarContext.Provider value={value}>
          <Container isOpen={isOpen}>
            <SidebarContainer isOpen={isOpen}>
              <SidebarWrapper isOpen={isOpen} scrollNav={scrollNav}>
                <SidebarTopSection>
                  <Fade bottom>
                    <SidebarLogo
                      onClick={() => toggleHome()}
                    >
                <NavImg isOpen={isOpen} src={theme === 'dark' ? logo : invertedLogo } alt='Sonar' />
                <NavImg isOpen={isOpen} src={theme === 'dark' ? logo2 : invertedLogo2} alt=' Muse'/>

                    </SidebarLogo>
                  </Fade>
                  <Fade bottom>{isOpen && <div style={{cursor: 'pointer'}}><FaTimes onClick={() => setOpen(false)} size={25}/></div>}</Fade>
                  {!isOpen && (
                    <SideBarLinkIcon isOpen={isOpen} onClick={() => setOpen(true)}>
                      <FaBars />
                    </SideBarLinkIcon>
                  )}
                </SidebarTopSection>
                <SidebarMenu>
                <ToolTip text={`Theme: ${theme === 'dark' ? 'Dark' : 'Light'}`}><ToggleButton customOffIcon={<TbBulbFilled/>} customOnIcon={<TbBulbOff/>}  toggleClick={toggleTheme} toggle={theme === 'dark'}/></ToolTip>
                  {menuItems().map((item, index) => {
                    if(item.name === 'Whitepaper'){return (
                      <ToolTip text={item.name}><SidebarLink
                        
                        onClick={() => {
                          setOpen(false)
                          downloadFile(whitepaper)
                        }}
                        to={item?.path ? item.path : `#${item.name}`}
                      >
                        <SideBarLinkIcon isOpen={isOpen}>{item.icon}</SideBarLinkIcon>
                        <SideBarLinkText isOpen={isOpen}>
                          {item.name}
                        </SideBarLinkText>
                      </SidebarLink></ToolTip>
                    );}
                    else{
                    return (
                      <ToolTip text={item.name}><SidebarLink 
                      /* isActive={
                        currentTab === item.name && 
                        (item.name !== 'More' 
                        && item.name !== 'Upload' 
                        && item.name !== 'Playlist'
                        && item.name !== 'NFT Creator')
                      } */
                        onClick={() => {
                          setTab(item.name)
                          setOpen(false)
                          item?.onClick && item.onClick()
                          item?.path && scroll.scrollToTop()
                        }}
                        to={item?.path ? item.path : `#${item.name}`}
                      >
                        <SideBarLinkIcon isOpen={isOpen}>{item.icon}</SideBarLinkIcon>
                        <SideBarLinkText isOpen={isOpen}>
                          {item.name}
                        </SideBarLinkText>
                      </SidebarLink></ToolTip>
                    );}
                  })}
                  <br/>
                  {!currentUser?.uid &&
                  <SidebarBtnLink isOpen={isOpen} to={'/signin'}>
                    {!isOpen && <SideBarLinkIcon isOpen={isOpen}>
                      <FaSignInAlt/>
                    </SideBarLinkIcon> }
                    <SideBarLinkText isOpen={isOpen}>Sign In</SideBarLinkText>
                  </SidebarBtnLink>
                 }
                </SidebarMenu>
              </SidebarWrapper>
            </SidebarContainer>
            
            <Main isOpen={true}><NavigationButtons/>{children}</Main>
          </Container>
      </SidebarContext.Provider>
    </>
  );
};

export default SidebarNav;
