import React, { useCallback, useEffect, useRef, useState } from "react";
import ContentList from "../ContentList";
import ContentWrapper from "../ContentWrapper";
import {
  MusicAboutWrapper,
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicDetailsWrapper,
  MusicHeaderWrapper,
  MusicHeading,
  MusicHeadingBgImage,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingImageWrapper,
  MusicHeadingSmall,
  MusicHeadingTextWrapper,
  MusicHeadingWrapper,
  MusicInfoWrapper,
  MusicLyricsWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageWrapper,
  MusicPlayerWrapper,
  MusicPlayPauseButton,
  MusicSubheading,
  Option,
  OptionButton,
  OptionMenuButton,
  OptionText,
  ProgressBar,
  ProgressBarWrapper,
  PurchaseButton,
  Select,
  SongDetailsText,
  SongDetailsWrapper,
  SubHeading,
  Text,
} from "./MusicPageElements";
import { FaPlay, FaPause, FaShare, FaRegMoneyBillAlt, FaCrown } from "react-icons/fa";
import { GiScrollUnfurled } from "react-icons/gi";
import { GoReport } from "react-icons/go";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { Slider } from "../Slider";
import Marquee from "react-text-marquee";
import { useAuth } from "../../contexts/AuthContext";
import { filterLikes, getArtistsNames, getSongTitle, stringFormatter, timestampToDate } from "../../utils";
import { useContext } from "react";
import { FcLikePlaceholder, FcLike } from "react-icons/fc";
import { AiOutlineEye, AiFillEye } from "react-icons/ai";
import { isUserPayingOut } from "../SonarMuseWebMonetization";
import { useNavigate, useLocation } from "react-router-dom";
import QRCodeForm from "../QRCodeForm";
import { useAlert } from "../Alert/AlertContext";

import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  MUSIC_PLAYING_FROM,
  MUSIC_PURCHASE_OPTIONS,
  SLIDER_TYPES,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import { useModal } from "../Modal/ModalContext";
import MusicInfo from "./MusicInfo";
import {MusicOptionsModal, OpenMusicStorePlayer } from "../MenuOptions";
import useMusic from "../../hooks/useMusic";
import { NavContainer } from "../NavLink/NavLinkElements";
import MusicEditPage from "../MusicEditPage";
import OptionModal from "../OptionModal";
import { FormButton, FormInput1 } from "../CustomForm/CustomFormElements";
import { HoverOption } from "../HoverMenu/HoverMenuElements";
import CommentForm from "../CommentForm";
import CommentsSection from "../CommentsSection";
import useArtist from "../../hooks/useArtist";
import ScrollToTop from "../ScrollToTop";
import { MdExplicit } from "react-icons/md";
import SongList, { MusicStoreSongList } from "../ContentList/SongList";
import { TextLink, TextRoute } from "../SignUp/SignUpElements";
import { VideoPlayerSkeleton } from "../Skeleton";
import { RiVideoFill } from "react-icons/ri";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";
import { getResizedImage } from "../../utils/FileUploadsUtils";
import DigitalPurchaseCard from "../Card/DigitalPurchaseCard";
import RoyaltyShareCard from "../Card/RoyaltyShareCard";
import useVideos from "../../hooks/useVideos";
import ImageUtils from "../../utils/ImageUtils";
import ShareButton from "../ShareButton";

function VideoPage({
  id,
  video,
  currentTab,
  setTab,
  updateWebPageIcon,
  query,
  location,
}) {
  const { currentUser, profiles, currentProfile } = useAuth();
  const {goTo} = ScrollToTop()
  const { defaultAlbumArt } = ImageUtils();
  const { modalContent, toggleModal, isOpen, openModal, closeModal } = useModal();
  const [similarVideos, setSimilarVideos] = useState({});
  const [artistVideos, setArtistSongs] = useState({});
  const [collections, setCollections] = useState({})
  const navigate  = useNavigate();
  const {searchArtistVideos} = useArtist({})
  const [isLoaded, setLoaded] = useState(false)
  const [isASLoaded, setASLoaded] = useState(false)
  const [isNFTLoaded, setNFTLoaded] = useState(false)
  const [isAlbumArtLoaded,setAlbumArtLoaded] = useState()
  const [albumArt, setAlbumArt] = useState(video?.albumArt)
  const { addAlert } = useAlert();
  const [isEditing, setEditing] = useState(false)

  
  useEffect(() => {
    const idParam = query.get("edit");
    if (idParam) {
      if (currentUser?.uid === video?.uploadedBy) {
        setEditing(true);
      }
    } else {
      setEditing(false);
    }
    return () => {}
  });


  useEffect(() => {
    updateWebPageIcon(video?.albumArt)
    return () => {}
  },[])

  useEffect(() => {
    if(!isAlbumArtLoaded){
      getResizedImage(video?.thumbnail).then(image => {
        setAlbumArt(image)
        
      }).catch(err => {
        setAlbumArt(video?.thumbnail)
      })
      setAlbumArtLoaded(true)
    }
  }, [])

  useEffect(() => {
    if(currentProfile !== 'artist' && currentProfile !== 'label'){
    navigate(-1)
    }else{
      
    if(currentUser?.uid !== video?.uploadedBy && ((currentProfile === 'label' && ((profiles?.label?.artists?.length > 0  && !profiles?.label?.artists?.some(a => video?.artists?.includes(artist => artist?.artistId === a?.artistId))) || profiles?.label?.artists?.length === 0))  || (currentProfile === 'artist' && !video?.artists?.some(a => a?.artistId === profiles?.artist?.artistId) ))){
      navigate(-1)
      
    }
  }
    return () => {}
  }, [id])
 

  return (
    <>
      <MusicPageWrapper>
        <MusicHeadingContainer>
          <MusicHeadingBgImage
           onContextMenu={(e) => {
            e.preventDefault();
          }}
            src={isAlbumArtLoaded ? albumArt : video?.albumArt}
            alt={video?.title}
          ></MusicHeadingBgImage>
          <MusicHeadingWrapper style={{alignItems: 'center'}}>
            
          <MusicHeadingImageWrapper>
            <MusicHeadingImage
              
              src={video?.thumbnail ? video?.thumbnail : defaultAlbumArt}
              alt={video?.title}
            />
          </MusicHeadingImageWrapper>
            <MusicHeadingTextWrapper>
            <SubHeading><small>{`Video`}</small></SubHeading>

              <MusicHeading>{video?.explicit && <MdExplicit/>}{video?.title}</MusicHeading>
              <MusicSubheading>
                <MusicHeading
                  style={{ cursor: "pointer" }}
                >
                  {video?.artists?.map((artist, index) => {
                    if (index < video?.artists.length - 1) {
                      return <div key={artist?.artistId} style={{marginRight: '0.5rem'}} onClick={() =>
                      {}
                      }>{artist.artistName + " · "}</div>;
                    } else {
                      return <div key={artist?.artistId} onClick={() =>
                      {}
                      }>{artist.artistName}</div>;
                    }
                  })}
                </MusicHeading>
              </MusicSubheading>
              <MusicHeadingSmall>{`${video?.genre?.name} · ${video?.isPreRelease ? `Pre Release ·` : ""}${video?.isPreRelease ? `${new Date(timestampToDate(video?.releaseDate)).toLocaleString('default', { month: 'long' })} ${new Date(timestampToDate(video?.releaseDate)).getDate()}, ${new Date(timestampToDate(video?.releaseDate)).getFullYear()}` : new Date(timestampToDate(video?.releaseDate)).getFullYear()}`}</MusicHeadingSmall>
              <MusicOptionsContainer>
              {currentUser?.uid === video?.uploadedBy && <><MusicPlayPauseButton onClick={() => {
                    const params = new URLSearchParams({ edit: true });
                    navigate(`${location.pathname}?edit=true`,{
                      replace:true},
                    );

                 }}>
                   Edit
                 </MusicPlayPauseButton>
                 </>}

                 <MusicPlayPauseButton transparent onClick={async () => {
                  openModal()
                  modalContent(<ShareButton url={`https://sonarmuse.org/video/${video?.videoId}`} media={video?.thumbnail} 
                    title={`Check out the music video for ${video?.tile} - ${getArtistsNames(video.artists,ARTIST_SEARCH_TYPES.NAME)}) on Sonar Muse`} />);
                }}>
                    Share
                 </MusicPlayPauseButton>
              </MusicOptionsContainer>
            </MusicHeadingTextWrapper>
          </MusicHeadingWrapper>
        </MusicHeadingContainer>
        {isEditing && <MusicEditPage type={"video"} content={video} />}
      
      </MusicPageWrapper>
    </>
  );
}

export default VideoPage;
