import React, { useEffect, useState } from "react";
import ContentList from "../ContentList";
import ContentWrapper from "../ContentWrapper";
import {
  AlbumArt,
  AlbumArtContainer,
  AlbumArtWrapper,
  DetailsWrapper,
  Img,
  MusicAboutWrapper,
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicDetailsWrapper,
  MusicHeaderWrapper,
  MusicHeading,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingTextWrapper,
  MusicInfoWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageContainer,
  MusicPageWrapper,
  MusicPlayerWrapper,
  MusicPlayPauseButton,
  MusicHeadingBgImage,
  MusicHeadingWrapper,
  MusicHeadingImageWrapper,
  MusicSubheading,
  MusicPriceWrapper,
  NftBadgeWrapper,
  NftLogo,
  Option,
  OptionButton,
  OptionText,
  PurchaseButton,
  Select,
  SongDetailsText,
  SongDetailsWrapper,
  SubHeading,
  Text,
  OptionMenuButton,
  MusicHeadingSmall,
} from "./MusicPageElements";
import { FaPlay, FaPause, FaShare, FaRegMoneyBillAlt } from "react-icons/fa";
import { GiScrollUnfurled } from "react-icons/gi";
import { GoReport } from "react-icons/go";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { Slider } from "../Slider";
import Marquee from "react-text-marquee";
import NftBadge from "../../images/nft_badge.png";
import { useAuth } from "../../contexts/AuthContext";
import {
  filterLikes,
  getAllArtists,
  getArtistsNames,
  getSongTitle,
} from "../../utils";
import { useContext } from "react";
import { FcLikePlaceholder, FcLike } from "react-icons/fc";
import { AiOutlineEye, AiFillEye, AiOutlineDeliveredProcedure } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";
import QRCodeForm from "../QRCodeForm";
import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  MINTING,
  MUSIC_PLAYER_ACTIONS,
  MUSIC_PLAYING_FROM,
  MUSIC_PURCHASE_OPTIONS,
  SLIDER_TYPES,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import Modal from "../Modal";
import ImageUtils from "../../utils/ImageUtils";
import MenuOptions from "../MenuOptions";
import { NavContainer } from "../NavLink/NavLinkElements";
import { CollectionSongList, MusicStoreSongList } from "../ContentList/SongList";
import { useAlert } from "../Alert/AlertContext";
import MusicEditPage from "../MusicEditPage";
import CommentForm from "../CommentForm";
import { useModal } from "../Modal/ModalContext";
import CommentsSection from "../CommentsSection";
import OptionModal from "../OptionModal";
import { HoverOption } from "../HoverMenu/HoverMenuElements";
import useArtist from "../../hooks/useArtist";
import ProgressBar from "../ProgressBar";
import { useUser } from "../../hooks/useUser";
import CollectionStats from "../MusicStatsPage/CollectionStats";
import { FormWrapper } from "../CustomForm/CustomFormElements";
import ShareButton from "../ShareButton";

function CollectionPage({
  id,
  collection,
  isSongPlaying,
  stopMainPlayer,
  deleteSong,
  hasPurchased,
  query,
  location,
}) {
  const { currentUser, currentProfile, profiles } = useAuth();
  const { getCollectionsByUser } = useUser({});
  const navigate = useNavigate();
  const { musicOptionsModal } = MenuOptions();
  const { defaultAlbumArt, NftBadge } = ImageUtils();
  const [requestPurchase, setRequest] = useState(false);
  const [music, setMusic] = useState();
  const [currentTab, setTab] = useState('tracklist')
  const { modalContent, toggleModal, openModal, closeModal } = useModal();
  const { getArtistName } = useArtist({});
  const [requestNFTMint, setMintRequest] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [artistName, setArtistName] = useState(
    collection?.issuer ? collection?.issuer : ""
  );
  const [collections, setCollections] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [songs, setSongs] = useState([]);
  const [body, setBody] = useState({});
  const { addAlert } = useAlert();

  //Function call to get info from IPFS
  //display information

  useEffect(() => {
    if(currentProfile !== 'artist' && currentProfile !== 'label'){
    navigate(-1)
    }else{
      
    if(currentUser?.uid !== collection?.issuer){
      navigate(-1)
    }
  }
    return () => {}
  }, [id])

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (params?.has("edit")) {
      if (currentUser?.uid === collection?.issuer) {
        setEditing(true);
      }
    } else {
      setEditing(false);
    }
    return () => {};
  });

  useEffect(() => {
    displayArtistName();
    return () => {};
  }, [collection]);

  useEffect(() => {
    getCollectionsByUser(collection?.issuer)
      .then((collections) => {
        if (collections?.length > 0) {
          setCollections(
            collections.filter(
              (c) => c?.collectionId !== collection?.collectionId
            )
          );
          setLoaded(true);
        } else {
          setLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoaded(true);
      });
    return () => {};
  }, [collection?.issuer]);

  const displayArtistName = async () => {
    return getArtistName(collection?.issuer)
      .then((aName) => {
        if (aName) {
          setArtistName(aName);
          return;
        }
        setArtistName(collection?.issuer);
        return;
      })
      .catch((err) => {
        setArtistName(collection?.issuer);

        console.error(err);
        return;
      });
  };

  const getSongs = () => {};

  const onSuccess = () => {};

  const togglePlay = (pl) => {
    return;
  };

  const isPlayingFrom = () => {
    if (
      JSON.parse(localStorage.getItem("isPlayingFrom"))?.playingFrom ===
        MUSIC_PLAYING_FROM.COLLECTION &&
      JSON.parse(localStorage.getItem("isPlayingFrom"))?.id ===
        collection.collectionId
    ) {
      return true;
    }
    return false;
  };

  const playSong = (i) => {};

  const playMusic = () => {};

  const playAllMusic = () => {};

  const albumSelector = (albums) => {};

  const playAlbum = (album) => {};

  const purchaseOptions = (e) => {};

  return (
    <>
      <MusicPageWrapper>
        <MusicHeadingContainer>
          <MusicHeadingBgImage
            src={collection?.cover?.url}
            alt={collection?.name}
          ></MusicHeadingBgImage>
          <MusicHeadingWrapper>
            <MusicHeadingImageWrapper>
              <MusicHeadingImage
                src={collection?.cover?.url}
                alt={collection?.name}
              />
            </MusicHeadingImageWrapper>
            <MusicHeadingTextWrapper>
              <SubHeading>
                <small>NFToken Collection</small>
              </SubHeading>
              <MusicHeading>{collection?.name}</MusicHeading>
              <MusicSubheading>
                {" "}
                <MusicHeading
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`#`)}
                >
                  {artistName}
                </MusicHeading>
              </MusicSubheading>
              <MusicHeadingSmall>
              {`${collection?.collectionType} · ${`${new Date(collection?.dateCreated).toLocaleString('default', { month: 'long' })} ${new Date(collection?.dateCreated).getDate()}, ${new Date(collection?.dateCreated).getFullYear()}`}`}

              </MusicHeadingSmall>
              <MusicOptionsContainer>
                {currentUser?.uid === collection?.issuer && (
                  <><MusicPlayPauseButton
                    onClick={() => {
                      const params = new URLSearchParams({ edit: true });
                      navigate(`${location.pathname}?edit=true`, {
                        replace: true,
                      });
                    }}
                  >
                    Edit
                  </MusicPlayPauseButton>
                  <MusicPlayPauseButton transparent onClick={() => {
                  openModal()
                    modalContent(<OptionModal heading={'Coming Soon'} additionalText={'Mint and migate your NFT Collection onto other blockchains'}>
                      <HoverOption onClick={() => {
                        closeModal()
                        modalContent()
                      }}>OK</HoverOption>
                    </OptionModal>)

                 }}>
                   Cross-Chain Minting <AiOutlineDeliveredProcedure style={{marginLeft: '2%'}}/>
                 </MusicPlayPauseButton>
                 <MusicPlayPauseButton transparent onClick={async () => {
                  openModal()
                  modalContent(<ShareButton url={`https://sonarmuse.org/collection/${collection?.collectionId}`} media={collection?.cover?.url} 
                    title={`The revolution of music ownership and collecting has now been tokenized! Check out the music NFT collection '${collection?.name}' on Sonar Muse`} />);
                }}>
                    Share
                 </MusicPlayPauseButton>
                  </>
                )}
              </MusicOptionsContainer>
            </MusicHeadingTextWrapper>
          </MusicHeadingWrapper>
        </MusicHeadingContainer>
        {!isEditing && (
         <>
         <NavContainer>
           <MusicNav>
             <MusicNavLink
               onClick={() => setTab("tracklist")}
               active={currentTab === "tracklist"}
             >
              `Music from ${collection?.name} Collection`
             </MusicNavLink>
             <MusicNavLink
               onClick={() => setTab("stats")}
               active={currentTab === "stats"}
             >
             Statistics
             </MusicNavLink>
           </MusicNav>
         </NavContainer>
         <MusicDetailsContainer>
          {currentTab === 'tracklist' && 
                      <ContentWrapper
                        heading={`Music from ${collection?.name} Collection`}
                      >
                        
                          <>
                            {collection?.albums?.length > 0 && (
                              <FormWrapper>
                                <ContentWrapper heading={`Albums`}>
                                  {collection?.albums.map((album) => {
                                    return (
                                      <ContentWrapper
                                        subheading={"Play"}

                                      >
                                        <CollectionSongList
                                          onButtonClick={(i) => {
                                            navigate(`/song/${album.songs[i]}`)
                                          }}
                                          type={CONTENT_TYPES.SONG}
                                          content={album.songs}
                                         
                                        />
                                      </ContentWrapper>
                                    );
                                  })}
                                </ContentWrapper>
                              </FormWrapper>
                            )}

                            {collection?.songs &&
                              collection?.songs?.length > 0 && (
                                <FormWrapper>
                                  <ContentWrapper
                        
                                    heading={`Songs`}
                                  >
                                    <CollectionSongList
                                      onButtonClick={(i) => {
                                        navigate(`/songs/${collection?.songs}`)
                                      }}
                                      type={CONTENT_TYPES.SONG}
                                      content={collection?.songs}
                                      
                                    />
                                  </ContentWrapper>
                                </FormWrapper>
                              )}
                          </>
                      
                      </ContentWrapper>
                    
            }
          {currentTab === 'stats' && <CollectionStats collection={collection?.collectionId} taxon={collection?.mintInfo?.taxon} issuer={collection?.issuer}/>}
          </MusicDetailsContainer>
          </>
        )}
        {isEditing && (
          <MusicEditPage content={collection} type={"collection"} />
        )}
      </MusicPageWrapper>
    </>
  );
}

export default CollectionPage;
