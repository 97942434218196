import React, { useEffect, useState } from "react";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import {
  Img,
  MainSlider,
  PlayButton,
  PlayContainer,
  SliderCard,
  SliderCardDescription,
  SliderCardDetails,
  SliderCardImg,
  SliderCardTitle,
  SliderContainer,
  SliderIcon,
  WaterMark,
} from "./SliderElements";
import defaultImg from "../../images/sm_album_art_placeholder.png";
import { useNavigate } from "react-router-dom";
import Marquee from "react-text-marquee";
import { DEFAULT_IMAGE } from "../GravatarAPI";
import { Fade } from "react-reveal";
import { colorRandomizer, getRandomColor, hexToRgba } from "../HexToRGBA";
import { useContext } from "react";
import { getArtistsNames, getSongTitle } from "../../utils";
import {
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  SLIDER_TYPES,
} from "../../utils/Enum";
import { useModal } from "../Modal/ModalContext";
import Card from "../Card";
import NFTokenCard, { GroupNFTokenCollectionCard } from "../Card/NFTokenCard";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import DigitalPurchaseCard from "../Card/DigitalPurchaseCard";
import UserCard from "../Card/UserCard";
import { ArtistCardSkeleton, GenreCardSkeleton, MusicCardSkeleton, PurchaseCardSkeleton, UserCardSkeleton } from "../Skeleton";
import DraftUploadCard from "../Card/DraftUploadCard";
import CollectionCard from "../Card/CollectionCard";
import DownloadCard from "../Card/DownloadCard";
import { useRef } from "react";
import SwerlCollectionCard from "../Card/CollectionCard/SwerlCollectionCard";
import RoyaltyShareCard from "../Card/RoyaltyShareCard";
import AlbumCard  from "../Card/AlbumCard";
import SongCard from "../Card/SongCard";
import ArtistCard from "../Card/ArtistCard";
import PlaylistCard from "../Card/PlaylistCard";
import PodcastCard from "../Card/PodcastCard";
import GenreCard from "../Card/GenreCard";
import VideoCard from "../Card/VideoCard";

export const Slider = ({
  id,
  background,
  slides,
  heightScroll,
  onSlideClick,
  selected,
  type,
  autoScroll,
  cardRef
}) => {
  const navigate  = useNavigate();
  const [isShowAll, setShowAll] = useState(false);
  const [visibleSlides, setSlides] = useState(slides);
  const [selectedSlide, setSelected] = useState(selected);
  let interval = useRef();
  const [sliderClick, setSliderClick] = useState(false)
 

  const Slide = (direction) => {
    let slider = document.getElementById(id ? id : "slider");
    setSliderClick(true)
    switch (direction) {
      case "left":
        slider.scrollLeft = slider.scrollLeft - 500;
        break;

      case "right":
        slider.scrollLeft = slider.scrollLeft + 500;
        break;
      default:
        break;
    }
  };

 /*  useEffect(() => {
      if (slides && slides?.length > 0) {
      if (isShowAll) {
        setSlides(slides);
      } else if (!isShowAll) {
        let s = slides?.filter((slide, index) => index <= 19);
        setSlides(s);
      } 
      if (selected && id === "genres") {
        //console.log(slides[selectedSlide]);
        setSlides([slides[selectedSlide]]);
      }
    } 
    return () => {}
  }, [selectedSlide, isShowAll]); */

  const slideClick = (i) => {
    setSelected(i);
    onSlideClick(i);
  };

  const playlistSlide = () => {
    if (!visibleSlides || visibleSlides?.length === 0) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        //console.log(id + ' index: ' + index)
        if(visibleSlides.length === index + 1) {return (
          <>
          
          <PlaylistCard cardRef={cardRef} content={slide} type={CONTENT_TYPES.PLAYLIST} />
          </>
        );}else
        {
          return (
          <>
          <PlaylistCard content={slide} type={CONTENT_TYPES.PLAYLIST} />
          
          </>
        );}
        
      });
    }
  };

  const genresSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        return (
          <>
            <Fade>
              <Card
                key={index}
                content={slide}
                type={CONTENT_TYPES.GENRE}
              />
            </Fade>
          </>
        );
      });
    }
  };

  const draftSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        return (
          <>
            <Fade>
              <DraftUploadCard
                key={index}
                content={slide}
                type={CONTENT_TYPES.DRAFT}
              />
            </Fade>
          </>
        );
      });
    }
  };

  const podcastSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        return (
          <>
            <Fade>
              <Card
                key={index}
                content={slide}
                type={CONTENT_TYPES.PODCAST}
                ref={cardRef}
                onClick={() => slideClick(index)}
              />
            </Fade>
          </>
        );
      });
    }
  };
  const podcastEpisodeSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        return (
          <>
            <Fade>
              <PodcastCard
                key={index}
                content={slide}
                type={CONTENT_TYPES.EPISODE}
                ref={cardRef}
                onClick={() => slideClick(index)}
              />
            </Fade>
          </>
        );
      });
    }
  };

  const collectionsSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
       if(visibleSlides.length === index + 1) {return (
          <>
          
            <CollectionCard cardRef={cardRef} key={index} content={slide} />
          </>
        );}else
        {
          return (
          <>
          
          <CollectionCard key={index} content={slide} />
          </>
        );}
      });
    }
  };

  const nfTokenCollectionsSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Empty</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
       if(visibleSlides.length === index + 1) {return (
          <>
          
            <GroupNFTokenCollectionCard cardRef={cardRef} key={index} content={slide}  />
          </>
        );}else
        {
          return (
          <>
          
          <GroupNFTokenCollectionCard key={index} content={slide}/>
          </>
        );}
      });
    }
  };

  const nftokenSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        return (
          <>
            <NFTokenCard type={CONTENT_TYPES.NFTOKEN} content={slide} />
          </>
        );
      });
    }
  };

  const songSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
       if(visibleSlides.length === index + 1) {return (
          <>
          
            <SongCard cardRef={cardRef}  key={index} content={slide} type={CONTENT_TYPES.SONG} />
          </>
        );}else
        {
          return (
          <>
          
          <SongCard key={index} content={slide} type={CONTENT_TYPES.SONG} />
          </>
        );}
      });
    }
  };

  const digitalSongSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
       if(visibleSlides.length === index + 1) {return (
          < >
          
            <DigitalPurchaseCard cardRef={cardRef}  key={index} content={slide} type={CONTENT_TYPES.SONG} />
          </>
        );}else
        {
          return (
          <>
          
          <DigitalPurchaseCard key={index} content={slide} type={CONTENT_TYPES.SONG} />
          </>
        );}
      });
    }
  };

  const artistSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)

        return (
          <>
            <ArtistCard key={index} content={slide} type={CONTENT_TYPES.ARTIST} onClick={() => onSlideClick(index)}/>
          </>
        );
      });
    }
  };

  const albumSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
       if(visibleSlides.length === index + 1) {return (
        <>
          
            <AlbumCard  cardRef={cardRef} key={index} content={slide} />
          </>
        );}else
        {
          return (
          <>
          
          <AlbumCard  key={index} content={slide} />
          </>
        );}
      });
    }
  };

  const labelSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
        return (
          <>
            <Card key={index} content={slide} type={CONTENT_TYPES.RECORD_LABEL} />
          </>
        );
      });
    }
  };


  const podcasterSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
        return (
          <>
            <Card key={index} content={slide} type={CONTENT_TYPES.PODCASTER} />
          </>
        );
      });
    }
  };


  const digitalAlbumSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>Coming Soon</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
       if(visibleSlides.length === index + 1) {return (
          <>
          
            <DigitalPurchaseCard  cardRef={cardRef} key={index} content={slide} type={CONTENT_TYPES.ALBUM} />
          </>
        );}else
        {
          return (
          <>
          
          <DigitalPurchaseCard key={index} content={slide} type={CONTENT_TYPES.ALBUM} />
          </>
        );}
      });
    }
  };

  const userSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>No Users</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
        return (
          <>
            <UserCard key={index} content={slide} />
          </>
        );
      });
    }
  };

  const swerlVoucherSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>No Users</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
        return (
          <>
            <SwerlCollectionCard key={index} content={slide} />
          </>
        );
      });
    }
  }

  const videoSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>No Downloads</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
       if(visibleSlides.length === index + 1) {return (
          <>
          
            <VideoCard cardRef={cardRef}  key={index} content={slide} onCardClick={onSlideClick} />
          </>
        );}else
        {
          return (
          <>
          
          <VideoCard key={index} content={slide} onCardClick={onSlideClick} />
          </>
        );}
      });
    }
  }

  const downloadSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>No Downloads</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
       if(visibleSlides.length === index + 1) {return (
          <>
          
            <DownloadCard cardRef={cardRef}  key={index} content={slide} onCardClick={onSlideClick} />
          </>
        );}else
        {
          return (
          <>
          
          <DownloadCard key={index} content={slide} onCardClick={onSlideClick} />
          </>
        );}
      });
    }
  }

  const royaltyShareSlide = () => {
    return visibleSlides.map((slide, index) => {
      if(visibleSlides.length === index + 1) {return (
        <>
        
          <RoyaltyShareCard cardRef={cardRef}  key={index} content={slide}/>
        </>
      );}else
      {
        return (
        <>
        
        <RoyaltyShareCard key={index} content={slide}/>
        </>
      );}
    })
  }

  const miscellaneousSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>No Content</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
        return (
          <>
            {slide?.userId && <UserCard key={index} content={slide} />}
            {slide?.artistId && (
              <ArtistCard key={index} content={slide} type={CONTENT_TYPES.ARTIST} />
            )}
            {slide?.albumId && !slide?.isDownloadable && (
              <SongCard key={index} content={slide} type={CONTENT_TYPES.ALBUM} />
            )}
            {slide?.songId && !slide?.isDownloadable && (
              <SongCard key={index} content={slide} type={CONTENT_TYPES.SONG} />
            )}
            {slide?.albumId && slide?.isDownloadable && (
              <DigitalPurchaseCard
                key={index}
                content={slide}
                type={CONTENT_TYPES.ALBUM}
              />
            )}
            {slide?.songId && slide?.isDownloadable && (
              <DigitalPurchaseCard
                key={index}
                content={slide}
                type={CONTENT_TYPES.SONG}
              />
            )}
            {slide?.collectionId && (
              <CollectionCard key={index} content={slide} />
            )}
            {slide?.nftoken && (
              <NFTokenCard key={index} content={slide} />
            )}
            {slide?.episodeId && (
              <PodcastCard key={index} content={slide} type={CONTENT_TYPES.EPISODE} />
            )}
            {slide?.podcasterId && (
              <Card key={index} content={slide} type={CONTENT_TYPES.PODCASTER} />
            )}
            {slide?.genreId && (
              <GenreCard key={index} content={slide} type={CONTENT_TYPES.GENRE} />
            )}
          </>
        );
      });
    }
  };

  const miscellaneousMusicSlide = () => {
    if (!visibleSlides) {
      return <WaterMark>No Users</WaterMark>;
    } else {
      return visibleSlides.map((slide, index) => {
        // //console.log(id + ' index: ' + index)
        return (
          <>
            {slide?.albumId && (
              <AlbumCard key={index} content={slide} type={CONTENT_TYPES.ALBUM} />
            )}
            {slide?.songId &&(
              <SongCard key={index} content={slide} type={CONTENT_TYPES.SONG} />
            )}
            {slide?.episodeId && (
              <PodcastCard key={index} content={slide} type={CONTENT_TYPES.EPISODE} />
            )}
          </>
        );
      });
    }
  };



  

  const artistSkeletonSlide = () => {
    let skeletons = [1,2,3]
    return skeletons.map(skeleton => {
      return <ArtistCardSkeleton/>
    }

    )
  }

  const userSkeletonSlide = () => {
    let skeletons = [1,2,3,4]
    return skeletons.map(skeleton => {
      return <UserCardSkeleton/>
    }

    )
  }

  const genreSkeletonSlide = () => {
    let skeletons = [1,2,3]
    return skeletons.map(skeleton => {
      return <GenreCardSkeleton/>
    }

    )
  }

  const musicSkeletonSlide = () => {
    let skeletons = [1,2,3]
    return skeletons.map(skeleton => {
      return <MusicCardSkeleton/>
    }

    )
  }

  const purchaseSkeletonSlide = () => {
    let skeletons = [1,2,3]
    return skeletons.map(skeleton => {
      return <PurchaseCardSkeleton/>
    }

    )
  }

  //console.log(id + ': ' + (visibleSlides ? visibleSlides.length: 0))
  let length = visibleSlides?.length ? visibleSlides?.length : 0;

  return (
    <>
      <SliderContainer>
        {visibleSlides && !heightScroll && visibleSlides?.length >= 3 && (
          <SliderIcon style={{ left: "2%" }} onClick={() => Slide("left")}>
            <FaChevronCircleLeft />
          </SliderIcon>
        )}
        <MainSlider height={heightScroll} isEpisode={type === SLIDER_TYPES.EPISODE || type=== SLIDER_TYPES.SWERL_VOUCHER} id={id ? id : "slider"}>
          {type === SLIDER_TYPES.ARTIST && visibleSlides && artistSlide()}
          {type === SLIDER_TYPES.DRAFT && visibleSlides && draftSlide()}
          {type === SLIDER_TYPES.DOWNLOAD && visibleSlides && downloadSlide()}
          {type === SLIDER_TYPES.SONG && visibleSlides && songSlide()}
          {type === SLIDER_TYPES.ALBUM && visibleSlides && albumSlide()}
          {type === SLIDER_TYPES.USER && visibleSlides && userSlide()}
          {type === SLIDER_TYPES.PLAYLIST && visibleSlides && playlistSlide()}
          {type === SLIDER_TYPES.PODCAST && visibleSlides && podcastSlide()}
          {type === SLIDER_TYPES.RECORD_LABEL && visibleSlides && labelSlide()}
          {type === SLIDER_TYPES.PODCAST  && visibleSlides && podcastSlide()}
          {type === SLIDER_TYPES.PODCASTER  && visibleSlides && podcasterSlide()}
          {type === SLIDER_TYPES.MISCELLANEOUS && visibleSlides && miscellaneousSlide()}
          {type === SLIDER_TYPES.MISCELLANEOUS_MUSIC && visibleSlides && miscellaneousMusicSlide()}
          {type === SLIDER_TYPES.SWERL_VOUCHER && visibleSlides && swerlVoucherSlide()}
          {type === SLIDER_TYPES.VIDEO && visibleSlides && videoSlide()}
          {type === SLIDER_TYPES.EPISODE &&
            visibleSlides &&
            podcastEpisodeSlide()}
          {type === SLIDER_TYPES.GENRE && visibleSlides && genresSlide()}
          {type === SLIDER_TYPES.COLLECTION &&
            visibleSlides &&
            collectionsSlide()}
          {type === SLIDER_TYPES.NFTOKEN &&
            visibleSlides &&
            nftokenSlide()}
          {type === SLIDER_TYPES.NFTOKEN_COLLECTIONS &&
            visibleSlides &&
            nfTokenCollectionsSlide()
            }
          {type === SLIDER_TYPES.DIGITAL_ALBUM &&
            visibleSlides &&
            digitalAlbumSlide()}
          {type === SLIDER_TYPES.DIGITAL_SONG &&
            visibleSlides &&
            digitalSongSlide()}
          {type === SLIDER_TYPES.PURCHASE_SKELETON && purchaseSkeletonSlide()}
          {type === SLIDER_TYPES.ARTIST_SKELETON && artistSkeletonSlide()}
          {type === SLIDER_TYPES.MUSIC_SKELETON && musicSkeletonSlide()}
          {type === SLIDER_TYPES.GENRE_SKELETON && genreSkeletonSlide()}
          {type === SLIDER_TYPES.USER_SKELETON && userSkeletonSlide()}
          {type === SLIDER_TYPES.MUSIC_ROYALTY_SHARE && royaltyShareSlide()}
          {!id &&
            visibleSlides?.length > 0 &&
            visibleSlides.map((slide, index) => {
              // //console.log(id + ' index: ' + index)
              return (
                <>
                  <Card key={index} content={slide} />
                </>
              );
            })}

          {/* visibleSlides &&
            !isShowAll &&
            !selected &&
            visibleSlides?.length > 10 && (
              <SliderCard
                onClick={setShowAll}
                style={{
                  background: "transparent",
                  width: "8rem",
                  boxShadow: "0px 0px 0px 0px",
                }}
              >
                custom styled version
                <PlayContainer style={{ bottom: "0" }}>
                  <SliderCardTitle style={{ color: background }}>
                    View all
                  </SliderCardTitle>
                </PlayContainer>
              </SliderCard>
            ) */}
          {!visibleSlides && 
            type !== SLIDER_TYPES.PURCHASE_SKELETON && 
          type !== SLIDER_TYPES.MUSIC_SKELETON && 
          type !== SLIDER_TYPES.USER_SKELETON && 
          type !== SLIDER_TYPES.ARTIST_SKELETON && 
            type !== SLIDER_TYPES.GENRE_SKELETON  && <WaterMark>Coming Soon</WaterMark>}
        </MainSlider>
        {visibleSlides && !heightScroll && visibleSlides?.length >= 3 && (
          <SliderIcon style={{ right: "2%" }} onClick={() => Slide("right")}>
            <FaChevronCircleRight />
          </SliderIcon>
        )}
      </SliderContainer>
    </>
  );
};
